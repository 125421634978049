import { FC, useCallback, useEffect, useState } from 'react';
import { Input } from './form-control/Input';
import { ariaAttributeProps } from '../../utils/ComponentUtils';
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
  value: string;
  onChange: (color: string) => void;
};

const ColorPicker: FC<Props> = (props) => {
  const { value, onChange, id } = props;

  const [textValue, setTextValue] = useState('');
  const { t } = useTranslation('common');

  const onPickerChange = useCallback(
    (color: string) => {
      setTextValue(color.slice(1).toUpperCase());
      onChange(color.toUpperCase());
    },
    [onChange],
  );

  const onTextChange = useCallback(
    (color: string) => {
      setTextValue(color);
      if (color.length === 6) {
        onChange(`#${color}`);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (value.startsWith('#')) {
      setTextValue(value.slice(1).toUpperCase());
    } else {
      setTextValue(value.toUpperCase());
    }
  }, [value]);

  const filterKeypresses = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Backspace' || e.key === 'Delete') return;

    if (!/^[0-9a-fA-F]*$/.test(e.key)) {
      e.preventDefault();
    }
  }, []);

  return (
    <div className="flex items-center gap-2">
      <input
        type="color"
        value={value}
        onChange={(e) => onPickerChange(e.target.value)}
        style={{ WebkitAppearance: 'none' }}
        className="max-h-10 min-h-10 min-w-10 max-w-10 cursor-pointer overflow-hidden rounded-[5px] bg-transparent p-0"
        aria-label={t('aria-label.colour-picker')}
      />
      <Input
        id={id}
        value={textValue}
        onChange={(e) => onTextChange(e.target.value)}
        onKeyPress={filterKeypresses}
        maxLength={6}
        errorState={textValue.length !== 6}
        className="min-w-16"
        {...ariaAttributeProps(props)}
      >
        <Input.Slot name="leading">
          <div className="text-gray-2">#</div>
        </Input.Slot>
      </Input>
    </div>
  );
};

export default ColorPicker;
