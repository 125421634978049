import { FC, useEffect, useState } from 'react';
import FileService from '../../../services/FileService';

type UploadedImageProps = {
  fileId: string;
  className?: string;
};

const UploadedImage: FC<UploadedImageProps> = (props) => {
  const { fileId, className } = props;
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    let url = '';
    FileService.getFile(fileId).then((fileBlob) => {
      url = URL.createObjectURL(fileBlob);
      setImage(url);
    });

    return () => {
      URL.revokeObjectURL(url);
    };
  }, [fileId]);

  return image ? <img src={image} className={className} /> : <></>;
};

export default UploadedImage;
